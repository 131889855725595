@import '../../styles/global';

.newest-tickets-titles {
  color: #696969;
  font-size: 13px;
  margin: 15px;
  text-transform: uppercase;
  background-color: #f2f2f2;
  padding: 1em 0;
  border-radius: 5px;
  font-weight: 600;
}

.newest-ticket-item {
  background: #fff;
  padding: 0px 10px;
  border: solid 2px $gainsboro;
  border-radius: 5px;
  margin: 15px;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .event-image {
    padding: 15px;
    padding-left: 5px;

    img {
      height: auto;
      width: 100%;
      border-radius: 5px;
    }
  }
}

@media (min-width: $tablet-max-width) {
  .newest-ticket-item-container {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
  }
}

.newest-ticket-item-content {
  align-items: center;
  color: $text-color;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: left;
  min-height: 100px;
  text-align: left;
}

.newest-ticket-item-title {
  color: $base-color;
  height: 100%;

  &:focus,
  &:hover {
    transition: 0.3s;

    h3 {
      color: $liputonorange;
    }
  }
}

.newest-tickets-holder {
  margin-top: 50px;
  padding: 50px;
  padding-top: 0;
}

.newest-ticket-button-holder {
  padding: 10px;

  button {
    font-size: 12px;
    color: #f6f6f6;
    text-transform: uppercase;
  }
}

.new-ticket-mobile-divider {
  display: none;
}

@media (max-width: $mobile-max-width) {
  .new-ticket-mobile-divider {
    clear: both;
    display: block;
    height: 5px;
    width: 100%;
  }

  .newest-tickets-titles {
    display: none;
  }

  .newest-ticket-item {
    margin: 15px;
    padding-bottom: 15px;
    padding-top: 15px;

    .event-image {
      padding: 0;
    }
  }

  .newest-ticket-button-holder {
    padding-bottom: 0;
  }

  .newest-ticket-item-content {
    display: block;
    min-height: 0;
  }

  .newest-tickets-holder {
    padding: 20px 15px;
  }

  .newest-ticket-button-holder {
    padding-left: 5px;
  }
}
