@import './global';

$hero-min-height: 600px;

.hero {
  left: 0;
  position: absolute;
  top: 0;
}

.background-image {
  background: linear-gradient($base-color, rgba(140, 34, 201, 0.8)), url('/assets/background.jpg');

  height: 55vh;
  min-height: $hero-min-height;
  width: 100%;
  width: 100vw;
  background-position: center center;
  background-size: cover;
  clip-path: url(#svgPath);
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.header {
  position: fixed;
  height: $header-height;
  width: 100%;
  top: 0;
  left: 0;
  transform: translate3d(0, 0px, 0);
  transition: 0.4s ease-in-out;
  z-index: 1200;
}

.header-bar {
  background: url('/assets/header-background.svg');
  // background-color: $base-color;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 105% auto;
  position: fixed;
  height: $header-height;
  width: 100%;
  color: #f1f1f1;
  z-index: 999;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background: $base-color;
    height: 50px;
    z-index: -1;
  }
}

.header-sell-tickets {
  border: 1px solid #fff;
}

.mainpage-header {
  //background-color: $base-color;
  //border-bottom-left-radius: 10% 70%;
  box-sizing: border-box;
  color: #fff;
  height: 50vh;
  min-height: $hero-min-height - 100px;
  padding: 60px 0;
  text-align: center;
}

.mainpage-header-title {
  font-weight: 600;
  font-size: 42px;
  letter-spacing: 2px;
  line-height: 1.1;
  text-transform: uppercase;
}

.svg-holder {
  position: absolute;
  width: 100%;
  height: 50vh;
}

.header-right {
  display: flex;
  float: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10px;
}

.shopping-toggle,
.header-right-toggle {
  display: none;
}

@media (min-width: ($mobile-max-width + 1px)) {
  .shopping-toggle-label,
  .header-right-toggle-label {
    display: none;
  }
}

@media (max-width: $mobile-max-width) {
  .header {
    &.header-hidden {
      transform: translate3d(0, -100px, 0);
      transition: 0.4s ease-in-out;
    }
  }
  .mainpage-header-title {
    font-size: 36px;
  }

  .header {
    height: $header-height-mobile;
  }

  .header-bar {
    border-radius: 0;
    height: $header-height-mobile;
  }

  .shopping-toggle-label {
    float: right;
  }

  .shopping-basket {
    left: 100%;
    transform: translate(0, 0);
    transition: all 0.15s ease-in-out;
  }

  .shopping-toggle-label {
    border: 1px solid #fff;
    border-radius: 3px;
    float: right;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3;
    margin: 15px 0 0;
    min-width: 100px;
    padding: 7px 15px;
    position: relative;
  }

  .shopping-toggle-cart {
    float: left;
    font-size: 22px;
    margin-right: 8px;
  }

  .shopping-toggle {
    &:checked + .shopping-basket {
      opacity: 1;
      transform: translate(-100%, 0);
      transition: all 0.15s ease-in-out;
    }
  }

  .header-right {
    background: $base-color;
    box-sizing: border-box;
    display: block;
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 90%;
    transition: all 0.15s ease-in-out;
    width: 100%;

    .nav-link {
      box-sizing: border-box;
      display: block;
      padding: 12px 25px;
    }
  }

  .header-right-toggle {
    display: block;
    height: 32px;
    left: -5px;
    position: absolute;
    top: -7px;
    width: 40px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;

    &:checked + .header-right {
      opacity: 1;
      transform: translate(100%, 0);
      transition: all 0.15s ease-in-out;
    }
  }

  .header-right-toggle-label {
    float: right;
    padding: 28px 25px 10px;
    margin-bottom: 0px;
    position: relative;

    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;

      background: #fff;
      border-radius: 3px;

      z-index: 1;

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }
}
