@import '../../styles/global';

.detail-title {
  color: #aaa;
  font-size: 13px;
  text-transform: uppercase;
  padding: 6px 0;
}

.mobile-category {
  display: none;
  font-size: 12px;
  margin-right: 4px;
  opacity: 0.6;
}

.sort--header {
  margin-left: -6px;

  &.sort--header--bargains {
    margin-left: 0;
    padding: 0 25px;
  }
}

.event-ticket-detail {
  font-size: 15px;
  padding: 6px 0;

  // Show line only if there are multiple tickets
  + .event-ticket-detail {
    border-top: 1px solid #ccc;
  }
}

.event-ticket-container {
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e2e2e2;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
  overflow: hidden;
  margin: 0 0 20px;
}

.event-ticket-content {
  background: #fff;
  border: 2px solid #d6d6d6;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.event-ticket-list-items {
  width: 100%;
  display: flex;
  transition: 1s;
}

.event-ticket-buy-all,
.event-ticket-buy {
  color: #fff;
  border-radius: 7px;
  font-size: 13px;
  line-height: 1.3;
  padding: 9px 10px;
  text-transform: uppercase;
  text-align: center;
}

.event-ticket-buy-all {
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}

.event-ticket-buy-all-container {
  padding-top: 1em;
  width: 10em;
}

.event-ticket-button {
  border-left: solid 1px #a0a0a0;
  margin: -20px;
  padding: 110px 0px;
  transition: 0.5s;
}

.event-ticket-buy-all,
.event-ticket-buy,
.event-ticket-button {
  background-color: $vivid-violet;
  background-image: linear-gradient(
    270deg,
    #cc99ff,
    $vivid-violet,
    $vivid-violet
  );
  background-size: 201% 100%;
  background-position: 99% 0%;
  transition: all 0.25s ease-in-out;

  &:focus,
  &:hover {
    background-color: darken($color: $vivid-violet, $amount: 10);
    background-position: 0% 0%;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
  }
}

.event-ticket-title {
  color: $vivid-violet;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
  transition: 0.2s;

  &:hover {
    color: $liputonorange;
    transition: 0.2s;
  }
}

.event-ticket-section {
  font-size: 18px;
}

.event-ticket-price {
  color: $liputonorange;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: $mobile-max-width) {
  .event-ticket-title {
    font-size: 20px;
  }
  .mobile-category {
    display: inline-block;
  }
}
