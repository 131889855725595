
@import '../../styles/global';

.most-viewed-button {
  background: rgba(255,255,255,1);
  border-radius: 15px;
  color: $vivid-violet;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px 5px;
  padding: 4px 12px 3px;
  text-transform: uppercase;
  transition: all .15s ease-in-out;

  &:focus,
  &:hover {
    background: rgba(255,255,255,0.8);
    transition: all .15s ease-in-out;
  }
}
