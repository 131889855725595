@import './global';

.active {
  border: solid 1px #979797;
}

.login-view {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
}

form .user-info {
  background: #f0f0f0;
  border: 2px solid #d7d7d7;
  border-radius: 5px;
  outline: 0;
  padding: 5px 10px;
  text-align: left;
  width: 100%;
}

.user-info-form .social-button {
  border: solid 1px #979797;
  border-radius: 5px;
  font-size: 11px;
  padding: 8px;
  text-decoration: none;
}

.verificationMessage {
  margin-top: -150px;
}

.socialbuttons {
  display: flex;
  align-items: center;
}

.login-register {
  display: inline;
  padding-right: 2em;
}

.modal-close-icon {
  cursor: pointer;
  &:hover {
    color: $base-color;
  }
}
