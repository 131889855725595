.bank-button {
  filter: grayscale(100%);
  margin: 30px;
  transition: all 0.15s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    filter: none;
    transition: all 0.15s ease-in-out;
  }

  input[type='image'] {
    max-width: 100%;
  }
}

.payment-result-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4em;
  justify-content: space-between;
}
