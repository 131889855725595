@import './global';

$footer-height: 100px;

.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 265px;
  margin: 0 auto;
  max-width: 1500px;
  padding-bottom: 2em;
}

.footer {
  line-height: 1.5;
  padding: 15px 0;
  z-index: 99;

  h3 {
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.footer-information {
  margin: 0;
  color: $text-color;
  font-size: 14px;
}

.footer-link {
  cursor: pointer;
  color: $text-color;
  margin: 0;
  font-size: 14px;
  display: block;
  &:hover {
    color: $base-color;
  }
}

.footer-bar {
  margin-bottom: 2em;
}

.inline-block {
  display: inline-block;
  min-width: 180px;
}

@media (max-width: $mobile-max-width) {
  .footer-push {
    padding-bottom: 0;
  }
}
