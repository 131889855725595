@import 'global';

// Text styles
html {
  //   font-size: 16px;
  //   color: $text-color-primary;

  //   @media screen and (min-width: 900px) {
  //     font-size: 18px;
  //   }

  //   @media screen and (min-width: 1200px) {
  //     font-size: 20px;
  //   }
  min-height: 100% !important;
  height: 100%;
}
label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  text-align: left;
  text-transform: uppercase;
}

label .content {
  text-transform: none;
  font-weight: normal;
}

.darker-label {
  color: $text-color-darker;
}

.primary-text {
  color: $text-color;
  font-weight: normal;
  text-transform: initial;
  &-uppercase {
    text-transform: uppercase;
  }
}

.login-notice {
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.modal-loading-container {
  padding: 50px;
}

.tl-h1 {
  font-size: 4rem;
}

.ReactModal__Overlay {
  z-index: 5000;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ReactModal__Overlay,
.ReactModal__Content {
  animation: fadein 0.2s;
}

.ReactModal__Content {
  box-shadow: 0 5px 15px -3px rgba(155, 155, 155, 0.2);
  transition: all 0.15s ease-in-out;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:required {
  box-shadow: none;
}

.loading-button {
  cursor: pointer;
  position: relative;
}

.link-like {
  span {
    cursor: pointer;
    color: $vivid-violet;
  }

  &:hover {
    text-decoration: underline;
  }
}

.loading-button .loading-button-indicator {
  position: absolute;
  right: 330px;
  top: -2px;
}

.root {
  overflow-x: hidden;
}

body {
  color: #696969;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
  word-wrap: break-word;
  min-height: 100% !important;
  height: 100%;
}

#root {
  min-height: 100% !important;
  height: 100%;
}

.react-pdf__Page__canvas {
  border: 2px solid #eee;
  border-radius: 2px;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.global-notification-enter {
  transform: translateY(-100%);
}

.global-notification-enter.global-notification-enter-active {
  transform: translateY(0%);
  transition: transform 400ms ease-in;
}

.global-notification-exit {
  opacity: 1;
}

.global-notification-exit.global-notification-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.SingleDatePicker_picker {
  z-index: 9;
}

.global-notification {
  background: rgba(240, 230, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 10px 10px;
  position: fixed;
  min-width: 40%;
  width: 300px;
  z-index: 9999;
}

.global-notification-content {
  background-color: #fff;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.global-notification-icon {
  padding: 0px 20px;
}

.global-notification-icon-container {
  box-shadow: 14px 6px 38px -15px rgba(240, 230, 255, 0.9);
  border-right: 2px solid rgba(240, 230, 255, 0.9);
  display: inline-block;
  margin: 20px 0;
}

.global-notification-text {
  color: #696969;
  margin-left: 20px;
}

.bigbold {
  font-size: 24px;
  font-weight: bold;
}

.bold {
  font-size: 16px;
  font-weight: bold;
}

.nav-link {
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin: 19px 0px;
  padding: 10px 20px;
  transition: 0.2s;
  color: #fff;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transition: 0.2s;
    border-radius: 8px;
    color: #fff;
  }

  &.language-select {
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  &:active,
  &:focus {
    color: #fff;
  }

  svg {
    height: 18px;
  }

  a {
    color: white;
    text-decoration: 'none';
  }
}

.nav-link-border {
  border: 1px solid #fff;
  border-radius: 8px;
  margin: 19px 10px;
}

.header-logo {
  float: left;
  padding: 12px 15px;
}

.footer-logo,
.header-logo {
  img {
    height: 50px;
  }
  padding-bottom: 1em;
}

.header-container {
  min-height: 40px;
  line-height: 40px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.break,
.break-xs,
.break-sm,
.break-md,
.break-lg {
  clear: both;
  width: 100%;
}

.break {
  height: 1px;
}

.break-xs {
  height: 5px;
}

.break-sm {
  height: 10px;
}

.break-md {
  height: 20px;
}

.break-lg {
  height: 40px;
}

.break-30vh {
  height: 30vh;
}

input[type='checkbox'] {
  margin: 5px;
}

/*
* Hide input type number arrows
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.account-number {
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  width: 100%;
  padding: 0.5em 1em;
}

input.event-search-input {
  display: inline-block;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-bottom: 0;
  font-size: 16px;
  height: 40px;
  vertical-align: top;
  width: 100%;
  border: 1px solid gray;
  padding: 15px;
}

.input-number-container {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  max-height: 36px;
}

.input-number-container-input{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input-number-container-input-error{
  justify-content: center;
  align-items: center;
  color: $liputonorange;
  font-size: 12px;
  padding: 0 15px;
  width: 100%;
  position: relative;
  display: flex;
  margin-bottom: 1em;
}

.slider-number-container-input-values {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rc-input-number-input {
  padding: 0.3em;
  border: 1px solid #d6d6d6;
  background-color: #f2f2f2;
  color: #010101;
  border-radius: 8px;
  text-align: center;
}

.react-select--language {
  .react-select--language__control {
    min-height: 20px;
  }

  .react-select--language__indicator {
    padding: 3px;
  }
}

.DayPicker {
  .CalendarDay__selected,
  .CalendarDay__selected_span {
    &,
    &:active,
    &:hover {
      background: $liputonorange;
      border-color: darken($liputonorange, 10%);
    }
  }
}

$rc-slider--border: #eee;

.rc-slider {
  .rc-slider-track {
    background-color: $vivid-violet;
  }

  .rc-slider-handle {
    background: #ccc;
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #dedede 71%,
      #c8c8c8 100%
    );
    border: 1px solid $rc-slider--border;
    height: 25px;
    margin-left: -13px;
    margin-top: -10px;
    width: 25px;

    &:hover {
      border-color: darken($rc-slider--border, 5%);
    }

    &:active {
      border-color: darken($rc-slider--border, 5%);
      box-shadow: 0 0 5px $vivid-violet;
    }
  }
}

.center-block {
  display: block;
  text-align: center;
}

.lander-benefits {
  background-image: linear-gradient(113deg, #f9f9f9, #ffffff);
  border: solid 1px #979797;
}

button {
  border: 1px solid #8e44ad;
  border-radius: 8px;
  background-color: $vivid-violet;
  background-image: linear-gradient(
    270deg,
    #cc99ff,
    $vivid-violet,
    $vivid-violet
  );
  background-size: 201% 100%;
  background-position: 99% 0%;
  color: #eee;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  &:focus,
  &:hover {
    background-color: darken($color: $vivid-violet, $amount: 10);
    background-position: 0% 0%;
    transition: all 0.25s ease-in-out;
  }

  &.btn-blank {
    background: transparent;
    border-color: transparent;
    color: #333;
    transition: 0.2s;

    &:hover {
      background: $vivid-violet;
      color: #fff;
      transition: 0.2s;
    }
  }

  &.approve-button {
    background-color: #27ae60;
    background-image: linear-gradient(270deg, #a3dbbf, #27ae60, #27ae60);
    background-size: 201% 100%;
    background-position: 99% 0%;
    border-color: #27ae60;
    color: #fff;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #27ae60;
      background-position: 0% 0%;
      border-color: #27ae60;
      color: #fff;
      transition: all 0.25s ease-in-out;
    }
  }

  &.secondary-button {
    background-color: #fff;
    background-image: none;
    border: solid 2px $base-color;
    color: $base-color;
    padding: 9px 19px;

    &:hover {
      background-color: $vivid-violet;
      background-image: none;
      border: solid 2px $vivid-violet;
      color: #eee;
      padding: 9px 19px;
      transition: all 0.25s ease-in-out;
    }
  }

  &.button-wide {
    width: 100%;
    margin-bottom: 10px;
  }
}

.ticket-edit-title {
  margin: 0;
  font-weight: 600;
}

button {
  .DayPickerNavigation_svg__horizontal,
  .DayPickerNavigation_button__default {
    color: #fff;
    fill: #fff;
  }
}

button[disabled='disabled'],
button[disabled] {
  background: mix($vivid-violet, #aaa, 5%) !important;
  border-color: #666 !important;
  cursor: not-allowed;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background: mix($vivid-violet, #aaa, 5%) !important;
    border-color: #666 !important;
  }
}

.container {
  background-image: linear-gradient(
    121deg,
    #ffffff,
    #fafafa 39%,
    #f7f7f7 56%,
    #f1f1f1
  );
  border: solid 1px #979797;
  width: 100vw;
}

h1 {
  color: $liputonorange;
  font-size: 40px;
  font-weight: 300;
  text-transform: uppercase;
}

.seller-success-icon {
  float: left;
  margin: 0 30px 20px 0;
  max-height: 200px;
  max-width: 150px;
}

.seller-success-container {
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

h2 {
  color: $liputonorange;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 15px;
  text-transform: uppercase;
}

p {
  margin: 0 0 10px;
}

.up-animated-text {
  position: absolute;
  color: $liputonorange;
  &-animate {
    animation: moving 300ms forwards ease-in-out;
  }
}

@keyframes moving {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(-30px);
  }
}

.orange-title {
  color: $liputonorange;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.lander-top {
  background-image: linear-gradient(
    121deg,
    #ffffff,
    #fafafa 39%,
    #f7f7f7 56%,
    #f1f1f1
  );
  border: solid 1px #979797;
}

.cursor-pointer {
  cursor: pointer;
}

h2.section-title {
  display: inline-block;
  margin: 0;
}

.section {
  padding: 50px;
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 0 0 0 0;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.left-menu {
  width: 250px;
}

.description-text {
  font-family: Poppins, sans-serif;
  font-weight: medium;
  color: #9b9b9b;
}

.container-margins {
  background-color: white;
  min-height: 100%;
  position: relative;
}

.setting-label {
  color: #888;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.layout-container {
  margin: 0 auto;
  max-width: 1500px;
  padding-top: $header-height;
  padding-bottom: 400px;
}

.language-select {
  width: 130px;
  font-size: 12px;
  color: black;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin: 16px 0px;
  padding: 10px 20px;
  transition: 0.2s;
}

ul {
  list-style-type: none;
}

// MOVE AWAY
.tl-translation-item {
  display: flex;
  flex-flow: column nowrap;
}

.tl-translation-row {
  display: flex;
  flex-flow: row wrap;

  .tl-translation-row__key {
    padding-top: 20px;
    padding-left: 20px;
    flex: 1;
  }

  .tl-translation-row__input {
    flex: 2;
  }
}

.visible-mobile {
  display: none;
}

@media (max-width: $mobile-max-width) {
  .layout-container {
    padding: $header-height $layout-container-padding-horizontal-mobile 0;
    padding-bottom: 300px;
  }

  .layout-container-undo {
    margin: 0 (-1 * $layout-container-padding-horizontal-mobile);
  }

  .hidden-mobile {
    display: none;
  }

  .visible-mobile {
    display: inline-block;
  }
}

.form-link {
  &:hover {
    color: $base-color;
  }
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 40px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 14px;
  color: #fff;
}

.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.fb-share-button {
  background-color: rgb(59, 89, 152);
  border-radius: 8px;
  color: #fff;
  display: inline-flex;
  padding: 3px 15px 3px 0;
  text-shadow: 0 -1px 0 #354c8c;
  transition: all 0.15s ease-in-out;

  svg circle {
    transition: all 0.15s ease-in-out;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: darken(rgb(59, 89, 152), 10%);

    svg circle {
      fill: darken(rgb(59, 89, 152), 10%);
      transition: all 0.15s ease-in-out;
    }
  }

  > div,
  > span {
    font-size: 14px;
    float: left;
    height: 36px;
    line-height: 36px;
  }
}

.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
  transition: all 0.15s ease-in-out;
  text-align: center;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png')
    6px 8px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
  transition: all 0.15s ease-in-out;
}

.full-width-button {
  width: 100%;
  border-radius: 0.2em;
  padding: 8px 20px;
}

.newest-tickets-container {
  background-color: $base-color;
  position: relative;
  padding-top: 1em;
  border-top-left-radius: 40em 5em;
  border-top-right-radius: 40em 5em;
  padding-left: calc((100vw - 100%) / 2);
  padding-right: calc((100vw - 100%) / 2);
  margin-right: calc((100% - 100vw) / 2);
  margin-left: calc((100% - 100vw) / 2);
  margin-bottom: 50px;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: -98px;
    height: 100px;
    clip-path: url(#svgPath);
    background-color: $base-color;
  }
}

.newest-tickets-title {
  text-transform: uppercase;
  color: #fff;
  font-weight: 100;
}

.extra-right-left-padding {
  padding: 0px 25px;
}

.header-tabs {
  position: relative;
  @media (min-width: $mobile-max-width) {
    &:after {
      background: #f2f2f2;
      border: 1px solid #d6d6d6;
      height: 6px;
      content: '';
      position: absolute;
      right: calc((100% - 100vw) / 2);
      left: calc((100% - 100vw) / 2);
      bottom: 0;
    }
  }
}

.tab-header {
  text-transform: uppercase;
  color: #888;
  min-height: 56px;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.tab-header,
.tab-header-active {
  svg {
    font-size: 15px;
    opacity: 0.4;
  }
}

.tab-header-active {
  text-transform: uppercase;
  min-height: 50px;
  color: $base-color;
  background-color: #f2f2f2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #d6d6d6;
  border-bottom: 0;
  margin-top: 1px;
  z-index: 10;
  display: flex;
  justify-content: center;
}

@media (max-width: $mobile-max-width) {
  .tab-header-active {
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    margin: 5px 15px;
  }
}

.tab-header-button {
  display: flex;
  align-items: center;
}

.tab-header-link {
  display: flex;
  color: $text-color-darker;
}

.tickets-for-sale {
  border: 1px solid #d6d6d6;
  background-color: #f2f2f2;
  margin: 2.5em 0;
  padding: 1em;
  color: $liputonorange;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-pc {
    font-size: 24px;
    margin: 0 10px;
    white-space: nowrap;
  }
}

.justify-content-left {
  display: flex;
  justify-content: flex-end;
}

.col-margin-left {
  margin-left: 15px;
}

.no-margin {
  margin: 0 !important;
}

.edit-ticket-container {
  margin-top: 0.5em;
  padding: 0 15px;
}

.DateInput {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  background: none !important;
  vertical-align: middle;
  border-radius: 5px;
}

.check-terms-container {
  text-align: left;
}
