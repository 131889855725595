@import './global';

.bargain-percentage {
  position: absolute;
  background: white;
  color: $vivid-violet;
  border: 1px solid currentColor;
  border-radius: 20px;
  text-align: center;
  bottom: -15px;
  height: 30px;
  padding: 5px 20px;
  right: 50%;
  transform: translateX(50%);
}

.sort--header-active {
  color: $vivid-violet;
}
