@import './global';

.translations-admin-input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: solid 1px #979797;
  width: 100%;
  text-align: center;
}

.translations-admin-key {
  width: 90%;
  border-bottom: solid 1px #979797;
}

.admin-page-nav {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  margin: 0 2px;

  &.active {
    background-color: $vivid-violet;
    color: #f0f0f0;
  }
}

.tl-container {
  max-width: 1140px;
  margin: 0px auto;
}

.tl-event-table {
  thead {
    font-weight: bold;
    color: $text-color-secondary;

    tr {
      border-bottom: 2px solid $text-color-secondary;
    }
    th {
      padding: 5px;
    }
  }
  tbody {
    tr {
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $white-smoke;
      }
    }
    td {
      padding: 5px;
      color: $text-color-primary;
    }
  }

  .tl-event-table__column--l {
    text-align: left;
  }
  .tl-event-table__column--r {
    text-align: right;
  }
}

button.admin-console--month-box {
  padding: 5px 5px;
  min-width: 80px;
  margin: 0 2px;

  &.selected-month-button {
    color: black;
  }
}

.admin-console--month-box--container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.month-button-selected {
  background-color: black;
}

.admin-panel--raports--form-container {
  margin: 30px 0;
}

.raport-radio {
  margin-left: 5px;
}

#salesTable thead {
  text-align: left;
}

#salesTable th, #salesTable td {
  padding: 0 5px 0 5px;
}

#salesTable .numeric {
  text-align: right;
}

.management--form--box {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 5px 5px 0;

  & div {
    margin: 15px 15px 15px 0;
  }
}

.basket--management--row {
  display: flex;
  padding: 15px;
}

.basket-management-ticket-row {
  margin: 15px;
  border: 1px solid;
}

.basket-management-ticket-row-deleted {
  background-color: rgb(209, 209, 209);
}

.basket-management--ticket-row--button {
  margin-left: 15px;
}
