@import './global';
$info-box-vertical-padding: 15px;
$progress-future: #fff;
$progress-current: #eee;
$progress-past: #eee;

$progress-green: #00c63b;
$progress-red: #920c0c;
$progress-bar-arrow: 13px;

.error-message {
  color: $error-red;
}

.success-message {
  color: $success-green;
}

.progress-bar {
  height: 25px;
  width: 100%;
  box-shadow: 0;
  box-sizing: border-box;
  padding: 0 5px 0 15px;
  line-height: 1.3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.15s ease-in-out;

  + .progress-bar {
    margin-left: 3px;
  }
}

.progress-bar-container {
  background-color: #f3f3f3;
  border: solid 1px #e1e1e1;
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 20px 0 10px;
}

.progress-bar:before {
  position: absolute;
  display: block;
  top: -2px;
  left: -2px;
  display: block;
  border-left: 15px solid #f3f3f3;
  border-top: $progress-bar-arrow solid transparent;
  border-bottom: $progress-bar-arrow solid transparent;
  width: 0;
  height: 0;
  content: '';
  transition: all 0.15s ease-in-out;
}

.progress-bar:after {
  z-index: 1;
  display: block;
  content: '';
  position: absolute;
  right: (-1 * ($progress-bar-arrow + 3));
  top: -2px;
  border-top: solid $progress-bar-arrow transparent;
  border-bottom: solid $progress-bar-arrow transparent;
  position: absolute;
  transition: all 0.15s ease-in-out;
}

.progress-bar-future {
  border: solid 2px $progress-future;
  background-color: $progress-future;
  color: #696969;
}
.progress-bar-history {
  border: solid 2px $progress-past;
  background-color: $progress-past;
  color: #696969;
}
.progress-bar-active {
  border: solid 2px $progress-current;
  background-color: $progress-current;
  color: #696969;
  font-weight: 600;
}
.progress-bar-future:after {
  border-left: solid 15px $progress-future;
}
.progress-bar-active:after {
  border-left: solid 15px $progress-current;
}
.progress-bar-history:after {
  border-left: solid 15px $progress-past;
}

.missing-values {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.rising {
  display: block;
  transform: rotate(-45deg);
}

.declining {
  display: block;
  transform: rotate(45deg);
}

.summary-title {
  text-align: center;
  font-size: 13px;
  color: #696969;
  text-transform: uppercase;
}

.summary-field {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #696969;
}

.single-sell-checkbox {
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
}

.SingleDatePicker {
  z-index: 2;
  width: 100%;
}

.SingleDatePickerInput__withBorder {
  width: 100%;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  border: 1px solid #d6d6d6 !important;
  background-color: #f2f2f2 !important;
}

.DateRangePickerInput__withBorder {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 10px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.DateInput_input {
  font-size: 14px !important;
  background-color: transparent !important;
  padding: 8px !important;
}

.ticket-add-button {
  border-radius: 0px 8px 8px 0px;
  background-image: linear-gradient(270deg, #cc99ff, #8d3fa6);
  color: #ddd;
  margin-left: -10px;
  padding: 0.5em 2em;
  border: none;
  &-icon-container {
    width: 30px;
  }
}

.ticket-error-container {
  margin: 20px 0 2px;
  min-height: 60px;
}

.ticket-buy-button {
  width: 92px;
  height: 33px;
  border-radius: 2px;
  background-color: #ededed;
  border: 0;
}

.ticket-code-container {
  height: 50px;
  display: flex;
  justify-content: center;
}

.ticket-code-icon {
  margin-left: 10px;
}

.ticket-code-input {
  background-color: #f2f2f2;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  max-width: 300px;
  width: 70%;
  padding: 0px 15px;

  @media (max-width: $mobile-max-width) {
    padding: 0px 7px;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    font-size: 14px;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aaa;
  }
}

.ticket-delete-icon-container {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  border-left: 2px solid #d6d6d6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-info-box-container {
  padding-bottom: 20px;
}

$horizontal-divider-height: 6px;

.horizontal-divider {
  margin: 10px 0 30px;
  position: relative;

  &:after {
    background: #f2f2f2;
    border: 1px solid #d6d6d6;
    height: 6px;
    content: '';
    position: absolute;
    right: calc((100% - 100vw) / 2);
    left: calc((100% - 100vw) / 2);
    bottom: 0;
  }
}

.horizontal-divider-box {
  background: #f2f2f2;
  border: 1px solid #d6d6d6;
  border-bottom: 0;
  box-shadow: 0px ($horizontal-divider-height - 1px) 6px inset
    rgba(0, 0, 0, 0.06);
  color: #8e44ad;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 10;
}

.ticket-sell-page--ticket-id {
  font-size: 13px;
  opacity: 0.7;
}

.ticket-delete--centered {
  height: 16px;
  padding: 0 10px 0;
}

.ticket-info-box {
  position: relative;
  margin: 2px 0;
  border-radius: 10px;
  border: 2px solid #d6d6d6;
  box-sizing: border-box;
  background-color: #f8f8f8;
  background-image: linear-gradient(90deg, #fff, #f4f4f4);
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.2);
  font-size: 13px;
  padding: $info-box-vertical-padding 15px;
}

.tickets-input {
  border: solid 1px #d6d6d6;
  border-radius: 3px;
  background-image: linear-gradient(360deg, #fff, #fafafa);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.tickets-input-box {
  background-color: #fff;
}

.tickets-input-container {
  background-color: $background-grey;
  margin-top: 40px;
  padding: 10px;
}

.ticket-sell-page--event-name {
  font-size: 16px;
  color: #000;
}

.ticket-sell-page--ticket-detail--row {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 250, 250, 1) 80%,
    rgba(242, 242, 242, 1) 100%
  );
  min-height: 50px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 10px 5px;
}

.ticket-detail--last-row {
  border-bottom: 1px solid #ccc;
}

.tickets-input-info-box {
  @media (min-width: $mobile-max-width) {
    color: #696969;
    background-image: linear-gradient(90deg, #fff, #fafafa);
    margin-bottom: -25px;
    margin-top: -60px;
    padding: 0;
    height: 75vh;

    border-right-width: 5px;
    border-right-style: solid;
    -webkit-border-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(black),
        to(rgba(0, 0, 0, 0))
      )
      1 100%;
    -webkit-border-image: -webkit-linear-gradient($gainsboro, #fff) 1 100%;
    -moz-border-image: -moz-linear-gradient($gainsboro, #fff) 1 100%;
    -o-border-image: -o-linear-gradient($gainsboro, #fff) 1 100%;
    border-image: linear-gradient(to bottom, $gainsboro, #fff) 1 100%;
  }
}

.ticket-sell-page--delete--icon {
  font-size: 1.5em;
  vertical-align: middle;
  line-height: 3em;
  text-align: center;
}

.ticket-item-container {
  padding: 15px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0 2px 7px 4px #d0d0d0;

  h3 {
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.ticket-sell-success-message {
  color: #25d013;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  margin: 20px 0 2px;
}

.ticket-sell-error-title {
  color: #ec6600;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.ticket-sell-error-message {
  color: #ec6600;
  font-size: 15px;
}

.gradientbox {
  height: 100%;
  background-image: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
  z-index: 1;
}

.label--thick {
  color: #010101;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
}

.ticket-item-container-holder {
  background-color: #f1f1f1;
  border: 1px solid rgba(214, 214, 214, 0.5);
  padding: 10px;
  margin-bottom: 20px;
}

.ticket-items-container {
  background: #f2f2f2;
  border: 1px solid rgba(214, 214, 214, 0.5);
  margin-top: 5px;
  padding: 5px 10px;
}

.ticket-items-titles {
  color: #696969;
  font-size: 12px;
  font-weight: bold;
  padding: 0 25px 5px;
  text-align: left;
  text-transform: uppercase;
}

.ticket-min-max-price {
  display: flex;
  justify-content: space-between;
}

.ticket-sell-box {
  background-image: linear-gradient(to left, #f3f3f3, #cacaca);
  border: solid 1px #979797;
  height: 15vh;
}

.ticket-sell-item-image {
  max-height: 256px;
  max-width: 355px;
}

.ticket-sell-event-name {
  font-size: 24px;
  font-weight: bold;
  color: #e68a00;
}

.ticket-sell-price {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ticket-sell-price--title {
  color: #010101;
  font-size: 14px;
  font-weight: 500;
  padding-right: 20px;
  text-align: right;
  text-transform: uppercase;
  width: 50%;
}

.ticket-sell-price--price {
  font-size: 22px;
  font-weight: 500;
  color: $liputonorange;
  text-align: left;
  width: 50%;
}

.ticket-sell-price-plan-box {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e7e7e7;
  min-height: 180px;
  min-width: 105px;
  padding: 5px;
  overflow: hidden;
}

.ticket-sell-price-plan-box-selected {
  border-radius: 2px;
  background-color: #f3f3f3;
  border: solid 1px #e7e7e7;
  min-height: 180px;
  padding: 5px;
  overflow: hidden;
}

.ticket-sell-form-container {
  margin-top: 15px;
}

.ticket-sell-step-border {
  border-bottom: solid 1px #d6d6d6;
}

.ticket-slider-container {
  margin-top: 20px;
}

.user-ticket-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #e2e2e2;
  margin-bottom: 20px;
}

.user-ticket-window {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.user-ticket-info-container {
  @media (max-width: 1199px) {
    margin-top: 0.51em;
    margin-left: 1em;
  }
}

.ticket-image {
  width: 100%;
  min-height: 145px;
  object-fit: cover;
}

.user-ticket-title {
  color: #696969;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 0 0 0;
}

.user-ticket-date {
  color: #696969;
  font-size: 13px;
  line-height: 1;
}

.user-ticket-location {
  color: #696969;
  font-size: 13px;
  padding-bottom: 2px;
}

.user-ticket-link {
  font-size: 12px;
}

.user-ticket-price {
  font-size: 13px;
  font-weight: 600;
  color: #696969;
}

.user-ticket-input {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: #f2f2f2;
  padding: 5px 10px;
}

.user-ticket-modify-button {
  padding-right: 10px;
  text-align: right;
  text-decoration-line: underline;
  font-size: 13px;
  color: #696969;
  display: inline;
  cursor: pointer;
  &:hover {
    color: $base-color;
  }
}

.ticket-links-container {
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  align-self: flex-end;
}

.user-ticket-last-sale-date {
  font-size: 13px;
  font-weight: 600;
  color: #696969;
}

.ticket-sell-detail-description {
  color: #999;
  display: inline-block;
  font-size: 12px;
  padding: 0 15px;
}

.ticket-sell-min-max-price {
  color: #999;
  font-size: 13px;
  margin-top: 8px;
}

.ticket-sell-bank-account-valid {
  position: absolute;
  right: 20px;
  top: 0;
}

.ticket-sell-error {
  color: $liputonorange;
  font-size: 14px;
  margin: 5px 0;
}

.CalendarMonth_caption {
  text-transform: capitalize;
}

.ticket-sell-date--title {
  color: $text-color;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

.ticket-sell-centered-calendar {
  > div {
    margin: 0 auto;
  }
}

// To prevent euro sign falling to next line for example
.data-block {
  display: inline-block;
}

@media (max-width: $mobile-max-width) {
  .ticket-info-box-container {
    margin-top: 0;
  }

  .ticket-item-container {
    padding: 10px 15px;
  }

  .ticket-items-titles {
    display: none;
  }

  .ticket-info-box {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .progress-bar {
    float: left;
    width: 49%;
  }

  .progress-bar-container {
    display: block;
    padding: 8px 17px 3px 8px;
  }

  .progress-bar {
    margin-bottom: 5px;
  }
}
