@import './global';

.all-inline p {
  display: inline;
}

.all-inline strong {
  font-weight: inherit;
}

.event-advert {
  border-radius: 3px;
  background-color: #dfdfdf;
  width: 280px;
  height: 160px;
}

.event-carousel-container {
  margin-bottom: 40px;
}

.event-description {
  h3 {
    color: $liputonorange;
  }
}

.event-description-text {
  font-size: 16px;
  font-weight: 400;
}

.event-list-item {
  margin-top: 25px;
}

.event-list-item-bottom {
  padding: 30px 10px 10px;
  background-color: white;
  min-height: 160px;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
}

.event-list-item-content {
  border: 2px solid #d6d6d6;
  border-radius: 5px;
  margin: auto;
  position: relative;
  height: 100%;
  transform: scale(1);
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
    transform: scale(0.985);
    transition: all 0.15s ease-in-out;
  }
}

.event-list-item-header__starting {
  font-size: 80%;
  font-weight: 300;
  margin-right: 8px;
}

.event-list-item-header__pricetext {
  font-size: 100%;
}

.event-list-item-details {
  padding: 0 15px 0 15px;
}

.event-list-item-header {
  height: 100%;
  object-fit: contain;
  background-color: lightgray;
  position: relative;

  img {
    border-radius: 3px 3px 0 0;
  }

  .event-list-item-header__price {
    background: white;
    border: 1px solid currentColor;
    border-radius: 20px;
    bottom: -15px;
    color: #8d3fa6;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.1;
    padding: 8px 20px;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(50%);
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
  }
}

.color-vivid {
  color: $vivid-violet;
}

@keyframes fadein-drop {
  from {
    opacity: 0;
    top: -5px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.event-list-item-holder {
  animation: fadein-drop 0.2s;
  background-color: $gainsboro;
  border: 1px solid #e2e2e2;
  padding: 10px;
  position: relative;
  top: 0px;
}

.event-list-item-image {
  width: 100%;
}

.event-menu-container {
  border-bottom: 3px solid $background-grey;
  color: $text-color;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-align: center;
}

.event-list-image {
  background: #eee;
  color: #999;
  display: inline-block;
  font-size: 12px;
  max-width: 100%;
  max-height: 100%;
  min-height: 40px;
}

.event-show-image {
  background: #eee;
  border: 3px solid #d5d5d5;
  border-radius: 8px;
  color: #ccc;
  max-width: 100%;
  min-height: 80px;
  width: 100%;
}

.event-show-title {
  color: $base-color;
  font-weight: normal;
  line-height: 1.3;
  margin: 20px 0 10px;
  text-transform: uppercase;
}

.event-show-location,
.event-show-date, .event-show-last-sales-date {
  color: $subtitle-grey;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.event-section-map {
  max-width: 450px;
  width: 100%;
}

.event-subevent {
  margin-top: 30px;
  background-color: #f0f0f0;
  padding: 10px;
}

.event-subevent-bottom {
  border: solid 1px #dfdfdf;
  background-color: #fdfdfd;
  box-shadow: 0 -2px 15px -8px rgba(0, 0, 0, 1);
  color: #5e5e5e;
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  transform: scale(1);
  transition: all 0.15s ease-in-out;
  width: 100%;

  &:focus,
  &:hover {
    box-shadow: 0 -2px 17px -7px rgba(0, 0, 0, 1);
    transform: scale(0.985);
    transition: all 0.15s ease-in-out;
  }
}

.event-subevent-left-info {
  h3 {
    margin-bottom: 5px;
  }
  p {
    margin-top: 0px;
  }
}

.search-result-text {
  font-style: italic;
}

.event-subevent-button {
  width: 60%;
}

.event-subevent-button-container {
  text-align: center;
  vertical-align: bottom;
  padding: 20px 0px;
}

.event-subevent-right-info {
  border: 2px solid #f0f0f0;
  border-left: 3px solid #f0f0f0;
  color: $base-color;
  line-height: 1.3;
  padding: 5px;
}

.event-subevent-value {
  font-size: 20px;
  font-weight: bold;
}

.event-ticket-amount {
  border-right: solid 1px #a0a0a0;
  padding: 25px 0;
  text-align: center;
}

.event-ticket-list-cell {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.event-ticket-list-headers {
  width: 100%;
  margin-bottom: 15px;
  margin-top: 50px;
}

.event-ticket-list-row {
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  height: 50px;
  margin-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0 8px 5px -6px rgba(0, 0, 0, 0.1);
}

.event-ticket-sum {
  padding: 15px 0;
  text-align: center;
}

.event-ticket-arrow {
  border: solid #fff;
  border-width: 0 20px 20px 0;
  display: inline-block;
  padding: 20px;
  transition: 0.2s;
}

.event-ticket-arrow-down {
  transform: rotate(45deg);
}

.event-ticket-arrow-right {
  margin-right: 1vh;
  transform: rotate(-45deg);
}

.event-ticket-sell-box {
  margin: 30px;
}
.event-ticket-resale {
  padding-top: 10px;
}

.event-list-item__name {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}

.event-list-item__info-text {
  color: $subtitle-grey;
  font-size: 14px;
  margin-top: 2px;
}

.plus-minus-counter {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.recommendedEvent {
  background-color: #ececec;
  border-radius: 3px;
  color: #444;
  display: inline-block;
  font-size: 14px;
  margin: 0 10px 10px 0;
  padding: 7px 20px 6px;
  position: relative;
  text-transform: capitalize;
  transform: scale(1);
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background: #ddd;
    transform: scale(0.97);
    transition: all 0.15s ease-in-out;
  }

  &.recommendedEvent-checked {
    background: $vivid-violet;
    color: #fff;
    transition: all 0.15s ease-in-out;
  }

  &.recommendedEvent-empty {
    font-weight: bold;
  }
}

.slick-dots {
}

@media (max-width: $mobile-max-width) {
  .slick-prev {
    left: -10px;
  }

  .slick-next {
    right: -10px;
  }
}

.slick-prev:before,
.slick-next:before {
  color: $vivid-violet;
  font-size: 25px;
}

.slick-dots li button::before {
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: rgb(0, 0, 0);
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
  color: $vivid-violet;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
  color: $vivid-violet;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $vivid-violet;
}

.batch-sell-page--flash-message {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  color: $success-green;
}

@media (max-width: $mobile-max-width) {
  .event-menu-container {
    text-align: left;
  }
}
