@import './global';

.userpage-info-container,
.login-form-container {
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #e2e2e2;
  position: relative;
  top: 0px;
}

.userpage-info-window,
.login-form-window {
  background-color: #fff;
  padding: 20px 40px;
  position: relative;
  top: 0px;
}

.login- .payment-card-info-container {
  border-radius: 15px;
  background-color: #f1f1f1;
  padding: 15px;
  margin: 10px;
}

.update-for-sale-ticket-container {
  border-left: solid 5px;
  border-left-color: $vivid-violet;
}

.history-ticket-container {
  padding: 15px 0px;
  min-height: 230px;
}

.history-info-title {
  color: #888;
  font-size: 13px;
  margin-right: 5px;
  text-transform: uppercase;
}

.history-sold {
  color: $success-green;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.user-page--user-info--header {
  margin-top: 2em;
}
