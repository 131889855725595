@import '../../styles/global';

.ticket-alarm-container {
  display: inline-block;
}

.ticket-alarm-explanation {
  color: #aaa;
  font-size: 11px;
  font-weight: 400;
  margin: 8px 0 10px;
  max-width: 300px;
  line-height: 1.3;
}

.ticket-alarm {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #777;
  font-size: 11px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 20px;
  padding: 3px 9px;
  text-transform: uppercase;
  transition: 0.2s;

  &:hover {
    border-color: $base-color;
    color: $base-color;
    transition: 0.2s;
  }

  // FA
  i,
  svg {
    margin-right: 5px;
  }
}
