@import './global';

$backgroundColor: #f2f2f2;
$textColor: #707070;
$counterColor: #00b257;

.basket-event-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.shopping-basket {
  background-color: $gainsboro;
  box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.16);
  opacity: 0.9;
  display: inline-block;
  min-width: 180px;
  padding: 0 15px 10px;
  position: fixed;
  top: $header-height;
  right: 0;
  cursor: pointer;
  text-align: center;

  .shopping-basket__header {
    font-size: 22px;
    margin: 10px 0;
  }

  p {
    margin: 0 0;
    color: $textColor;
  }
}

.shopping-counter-title {
  color: $counterColor;
  font-size: 14px;
  margin-top: 5px;
}

.shopping-counter {
  color: $counterColor;
  font-size: 24px;
}

.basket-image {
  border: 3px solid #d5d5d5;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
}

.basket-button {
  background-color: $vivid-violet;
  background-image: linear-gradient(270deg, #cc99ff, $vivid-violet);
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background: darken($color: $vivid-violet, $amount: 10);
    transition: 2s;
  }
}

.delivery-info-container {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 100px;
}

.delivery-info {
  font-family: poppins, sans-serif;
  height: 100%;
  padding: 0 25px;
}

.delivery-info-title {
  text-transform: uppercase;
  color: #010101;
}

.ticket-summary {
  background-color: #fff;
  border: solid 1px #e0e0e0;
  box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  font-family: poppins, sans-serif;
  height: 100%;
  margin-left: -30px;
  padding: 25px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.shopping-email-input {
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
  width: 100%;
}

@media (max-width: $mobile-max-width) {
  .ticket-summary {
    margin-left: 0;
  }
}
