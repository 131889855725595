@import '../../../styles/global';

button.submit-button {
  border-radius: 7px;
  box-shadow: 0 4px 10px 0 rgba(155, 155, 155, 0.2);
  font-size: 15px;
  font-weight: bold;
  padding: 10px 25px;
  text-transform: uppercase;

  &.submit-button-full {
    width: 100%;
  }
}
