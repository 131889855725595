
@import '../../../styles/global';

.event-info-box {
  background: #F0F0F0;
  border: 1px solid #ddd;
  color: $liputonorange;
  float: right;
  margin-left: 10px;
  padding: 5px 15px;
}

.event-info-box--title {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  margin-right: 5px;
}

.event-info-box--value {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
}
