@import './global';

.mainpage-upcoming-events {
  background-color: #ffffff;
  border: solid 1px #979797;
}

.mainpage-menu-container {
  overflow: visible;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 30px;
  text-align: left;
  color: $menucolor;
  margin-left: 15px;
}

.mainpage-upper {
  height: 310px;
}

#mainPageInput {
  width: 40vw;
}

@keyframes mainpage-dropdown {
  from {
    opacity: 0;
    top: 90%;
  }
  to {
    opacity: 1;
    top: 100%;
  }
}

.mainpage-dropdown {
  animation: mainpage-dropdown 0.2s;
  background-color: #f8f8f8;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 22px -2px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 100%;
  z-index: 999;
}

.dropdown-button {
  background: rgba(0, 0, 0, 0);
  color: #333;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
  position: relative;
  text-align: left;
  text-transform: capitalize;
  transition: all 0.15s ease-in-out;

  &:focus,
  &:hover {
    background: rgba(0, 0, 0, 0.03);
    color: $vivid-violet;
    padding-left: 25px;
    padding-right: 15px;
    transition: all 0.15s ease-in-out;
  }
}

.menu-button {
  display: inline-block;
  position: relative;
  color: #696969;

  &:focus,
  &:hover {
    color: $vivid-violet;
    transition: 0.3s;

    p {
      color: $vivid-violet;
    }
  }

  + .menu-button {
    border-left: 3px solid $background-grey;
    padding-left: 15px;
  }

  a {
    text-decoration: none;

    &:hover {
      color: $vivid-violet;
      text-decoration: none;
    }
  }

  &.menu-button-active {
    color: $vivid-violet;
  }
}

.menu-button-link {
  color: #696969;
  cursor: pointer;
  display: inline-block;
  margin: 7px 0;
  padding: 0px 20px 0 0;
  &-active {
    color: $vivid-violet;
    cursor: pointer;
    display: inline-block;
    margin: 7px 0;
    padding: 0px 20px 0 0;
  }
}

.social-sharing {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 40px 20px;
  .social-sharing-list {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    li {
      list-style: none;
      margin: 0px 5px;
    }
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
