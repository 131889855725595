$vivid-violet: #8d3fa6;
$liputonorange: #ef8030;
$tangerine: #e68a00;
$gainsboro: #f2f2f2;
$deep-lilac: #945eb3;
$charcoal: #4a4a4a;
$white-smoke: #f0f0f0;
$menucolor: #696969;
$base-color: #8e44ad;
$text-color: #696969;

$text-color-primary: $text-color;
$text-color-secondary: lighten($text-color, 30%);
$text-color-darker: #010101;

$subtitle-grey: #777;
$header-height: 100px;
$header-height-mobile: 70px;

$background-grey: $white-smoke;

$mobile-max-width: 767px;
$tablet-max-width: 992px;

// $error-red: rgb(160, 62, 62);
$error-red: #cc2d2d;
//$success-green: rgb(47, 165, 125);
$success-green: #89de0a;

$layout-container-padding-horizontal-mobile: 15px;
